// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { IMG_API } from "../../../components/images/docs/api"
import ImageFooted from "../../../components/widgets/imageFooted"

type DataProps = {
    site: {
        buildTime: string
    }
}

const GuiaLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS GuiaDocs">
            <div className="_compact">
                <ApiLogo format={'all'} />
                <h2>Guía para obtener tus certificados CSD</h2>
                <p className="intro">
                    <b>Si es la primera vez que obtienes tu CSD esta guia te ayudará a obtenerlo.</b> <br />
                    El CSD es un certificado MUY SIMILAR a la fiel, <b>Se obtiene de la FIEL pero no son lo mismo, </b> la FIEL es tu llave de identificación como contribuyente, el CSD es un certificado que usas con PACs para que podamos facturar en tu nombre.
                </p>
                <h3>
                    Pasos para obtener el CSD de la FIEL.
                </h3>
                <ol>
                    <li>
                        <p>
                            <b>Descargar Certifica:</b> Antes conocido como SOLCEDI, es una aplicación de JAVA del SAT que te permitirá crear
                            tu solicitud de Certificados de Sello Digital (CSD). En <a href="https://portalsat.plataforma.sat.gob.mx/certifica/" target="_blank">el portal del SAT puedes descargar la aplicación</a>,
                            recomendamos la versión de 32 bits, aunque tu computadora sea reciente, pues la versión de 64 bits es inestable.
                            <br />
                            <span className="accent">NOTA:</span> Para ejecutar esta aplicación es necesario contar con
                            <code>java -version</code> 1.8+, para saber si cuentas con java, ejecuta
                            <code>&gt;$ java -version</code> desde tu consola. Si es versión 1.8+ ya estás listo, de lo contrario
                            <a href="https://www.java.com/es/download/" target="_blank">descarga JVM</a>.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Ejecuta Certifica:</b> Para ejecutar el programa llamamos
                            <code>&gt;$ java -jar Certifica.jar</code> desde nuestra consola, o damos doble click al icono de la aplicación descargada y pedirle que se abra con JVM (si pregunta).
                        </p>
                    </li>
                    <li>
                        <p>
                            Al ejecutar el programa, damos click en la opción <b>Solicitud de certificados de sello digital (CSD)</b>. Nos pedirá nuestro <b>Certificado de firma electrónica vigente
                                <code>*.cer</code>
                            </b>, el cual lo obtuvimos de nuestra cita para obtener la FIEL.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Llenar los campos:</b> En el paso siguiente veremos que nos pide <b>Nombre de la sucursal</b> y <b>Contraseña de la clave privada</b>. <br />
                            El nombre de sucursal se refiere a tener más de una sucursal que emita facturas,
                            y nos permite identificar quien la firmó, la contraseña que usemos será para nuestra llave del CSD, puedes usar la
                            misma contraseña de tu FIEL si lo deseas. <br />
                            <span className="accent">NOTA:</span>Si tu empresa no cuenta con sucursales, te recomendamos usar <b>"Matriz"</b> en este campo.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Enviar solicitud (SDG):</b> al terminar guardaremos un archivo con terminación
                            <code>*.sdg</code>, este lo utilizamos para solicitar nuestro CSD en el portal <a href="https://aplicacionesc.mat.sat.gob.mx/certisat/" target="_blank">Certisat Web</a>, siguiendo las instrucciones que nos muestra (Envío de solicitud de certificados de sello digital).
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Último paso:</b> <span className="accent">Después de algunos días, </span>podremos descargar los los archivos, veremos que el CSD cuenta con dos archivos,
                            un <code>.key</code> y un <code>.cer</code>, éstos son los archivos que se agregan a tu cliente en FiscalPOP.<br />
                            <span className="accent">NOTA:</span> Es común tener que esperar 5 días posteriores a obtener tu CSD para poder facturar.
                        </p>
                    </li>
                </ol>
                <div className="guiaImages">
                    <ImageFooted explanation={`Las opciones iniciales que verás en el programa CERTIFICA.`}>
                        <IMG_API file={'certifica'} />
                    </ImageFooted>
                    <ImageFooted explanation={`Paso donde generarás la contraseña para la llave del CSD.`}>
                        <IMG_API file={'certificaPasos'} />
                    </ImageFooted>
                </div>
            </div>
        </div >
    </Layout >
)

export default GuiaLanding
