import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

type FILE_OPTIONS = 'validation' | 'certifica' | 'certificaPasos';

export const IMG_API: React.FC<{ file: FILE_OPTIONS }> = ({ file }) => {
  const data = useStaticQuery(graphql`
    query {
      validation: file(relativePath: { eq: "docimages/api_ValidationEmail.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      certifica: file(relativePath: { eq: "docimages/Certifica.png" }) {
        childImageSharp {
          fixed (width:675){
            ...GatsbyImageSharpFixed
          }
        }
      }
      certificaPasos: file(relativePath: { eq: "docimages/CertificaPasos.png" }) {
        childImageSharp {
          fixed (width:610){
            ...GatsbyImageSharpFixed
          }
        }
      }
      
    }
  `)
  if (file === 'validation') {
    return (
      <Img
        style={{ maxWidth: '100%' }}
        imgStyle={{objectFit:'contain'}}
        fixed={data.validation.childImageSharp.fixed}
        alt="Configurar certificados"
      />
    )
  }
  else if (file === 'certifica') {
    return (
      <img src={data.certifica.childImageSharp.fixed.src} />
    )
  }
  else if (file === 'certificaPasos') {
    return (
      <img src={data.certificaPasos.childImageSharp.fixed.src} />
    )
  }
}
