import React from 'react';

interface LogoProps {
    format: 'all' | 'icon' | 'text'
}

const LogoAPI: React.FC<LogoProps> = ({ format }: LogoProps) => {

    const viewBoxDimensions = () => {
        if (format === 'all') {
            return '0 0 616.04 311.71'
        } else if (format === 'icon') {
            return '240 18 155 155'
        } else {
            return '36 196 562 77'
        }
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="ApiLogo" x="0px" y="0px" viewBox="0 0 788.04 194.71" >
            <g>
                <g className="_brand">
                    <g>
                        <g>
                            <g>
                                <path className="st3" d="M189.99,59.86h53.37c0,0-2.56,16.2-12.5,16.2s-22.38,0-22.38,0V87.4h25.86c0,0,0.78,15.34-10.59,15.34       c-11.36,0-15.27,0-15.27,0s0,4.03,0,13.97s-18.49,9.85-18.49,9.85V59.86z" />
                                <path className="st3" d="M249.38,59.86c0,0,18.58,0.04,18.58,9.99s0,56.72,0,56.72s-18.58-1.33-18.58-11.27       S249.38,59.86,249.38,59.86z" />
                                <path className="st3" d="M278.03,116.65l10.29-12.29c6.58,5.24,13.91,8,21.63,8c4.96,0,7.62-1.71,7.62-4.57v-0.19       c0-2.76-2.19-4.29-11.24-6.38c-14.2-3.24-25.16-7.24-25.16-20.97v-0.19c0-12.39,9.81-21.35,25.82-21.35       c11.34,0,20.2,3.05,27.45,8.86l-9.24,13.05c-6.1-4.29-12.77-6.57-18.68-6.57c-4.48,0-6.67,1.91-6.67,4.29v0.19       c0,3.05,2.29,4.38,11.53,6.48c15.34,3.34,24.87,8.29,24.87,20.78v0.19c0,13.63-10.77,21.73-26.97,21.73       C297.47,127.71,286.23,123.99,278.03,116.65z" />
                                <path className="st3" d="M340.33,93.4v-0.19c0-19.44,14.96-34.69,35.16-34.69c13.63,0,22.39,5.72,28.3,13.91L389.89,83.2       c-3.81-4.76-8.2-7.81-14.58-7.81c-9.34,0-15.92,7.91-15.92,17.63v0.19c0,10.01,6.58,17.82,15.92,17.82       c6.96,0,11.05-3.24,15.06-8.1l13.91,9.91c-6.29,8.67-14.77,15.06-29.54,15.06C355.67,127.9,340.33,113.32,340.33,93.4z" />
                                <path className="st3" d="M454.3,99.89l-7.43-18.96l-7.53,18.96c0,0-3.89,9.36-7.14,19.29c-3.22,9.83-22.5,7.01-22.5,7.01       l28.4-67.18h17.82l28.4,67.18c0,0-18.03,1.51-22.3-7.01C457.76,110.66,454.3,99.89,454.3,99.89z" />
                                <path className="st3" d="M492.15,59.86c0,0,18.49-1.38,18.49,11.41s0,39.1,0,39.1s2.82,0,17.03,0c14.2,0,15.28,16.2,15.28,16.2       h-50.79V59.86z" />
                            </g>
                            <path className="st3" d="M549.71,59.86h28.59c16.87,0,27.73,8.67,27.73,23.44v0.19c0,15.72-12.1,24.01-28.68,24.01h-9.15      c0,0,0-0.92,0,9.12s-18.49,9.94-18.49,9.94V59.86z M576.97,93.02c6.57,0,10.58-3.43,10.58-8.67v-0.19c0-5.72-4-8.77-10.67-8.77      h-8.67v17.63H576.97z" />
                            <path className="st3" d="M611.98,93.4v-0.19c0-19.15,15.44-34.69,36.02-34.69c20.58,0,35.83,15.34,35.83,34.5v0.19      c0,19.15-15.44,34.69-36.02,34.69C627.23,127.9,611.98,112.55,611.98,93.4z M664.96,93.4v-0.19c0-9.62-6.96-18.01-17.15-18.01      c-10.1,0-16.87,8.19-16.87,17.82v0.19c0,9.62,6.96,18.01,17.06,18.01C658.2,111.22,664.96,103.02,664.96,93.4z" />
                            <path className="st3" d="M691.5,59.86h28.59c16.87,0,27.73,8.67,27.73,23.44v0.19c0,15.72-12.1,24.01-28.68,24.01h-9.15      c0,0,0-0.92,0,9.12s-18.49,9.94-18.49,9.94V59.86z M718.76,93.02c6.58,0,10.58-3.43,10.58-8.67v-0.19c0-5.72-4-8.77-10.67-8.77      h-8.67v17.63H718.76z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="st3" d="M740,119.74h3.65c1.18,0,2,0.31,2.52,0.84c0.45,0.44,0.68,1.04,0.68,1.8v0.02c0,1.18-0.63,1.96-1.59,2.37      l1.84,2.69h-2.47l-1.56-2.34h-0.94v2.34H740V119.74z M743.55,123.45c0.73,0,1.15-0.35,1.15-0.92v-0.02      c0-0.61-0.44-0.92-1.16-0.92h-1.4v1.85H743.55z" />
                        </g>
                        <path className="st3" d="M743.07,131.74c-4.3,0-7.8-3.5-7.8-7.8s3.5-7.8,7.8-7.8c4.3,0,7.8,3.5,7.8,7.8S747.37,131.74,743.07,131.74z      M743.07,117.15c-3.74,0-6.79,3.05-6.79,6.79c0,3.74,3.05,6.79,6.79,6.79c3.74,0,6.79-3.05,6.79-6.79     C749.86,120.2,746.82,117.15,743.07,117.15z" />
                    </g>
                    <g>
                        <path className="st3" d="M100.93,17.34c-40.1,0-72.6,32.51-72.6,72.6c0,40.1,32.51,72.6,72.6,72.6s72.6-32.51,72.6-72.6     C173.53,49.85,141.02,17.34,100.93,17.34z M60.64,77.53v-0.15c0-12.38,8.19-18.91,19.42-18.91h6.19v-4.69     c0-7.9,12.51-10.94,12.51-10.94v30.69l-18.45,0.09c-4.45,0-7.31,0.21-7.31,4.33v0.15c0,4.5,2.86,5.66,7.37,5.66h5.71v0.04h12.68     v12.19H79.42C68,95.99,60.64,89.16,60.64,77.53z M98.91,134.6c0,0-12.51-3.04-12.51-10.87c0-5.15,0-6.39,0-6.65     c0,0-2.65,0-10.34,0S68.89,105,68.89,105h1.41v0.05h28.61V134.6z M105.34,42.84c0,0,12.51,3.04,12.51,10.87c0,7.83,0,8.51,0,8.51     s2.65,0,10.34,0s7.17,12.08,7.17,12.08h-1.41v-0.05h-28.61V42.84z M143.6,101.92c0,12.38-8.19,18.91-19.42,18.91h-6.19v2.82     c0,7.9-12.51,10.94-12.51,10.94v-28.82l18.45-0.09c4.45,0,7.31-0.21,7.31-4.33v-0.15c0-4.5-2.86-5.66-7.37-5.66h-5.71v-0.04     h-12.68V83.31h19.35c11.42,0,18.77,6.83,18.77,18.46V101.92z" />
                    </g>
                </g>
                <g className="_type">
                    <path className="st3" d="M613.87,145.74h8.2l13.08,30.93h-9.13l-2.24-5.49h-11.85l-2.19,5.49h-8.95L613.87,145.74z M621.33,164.56    l-3.42-8.73l-3.46,8.73H621.33z" />
                    <path className="st3" d="M673.18,145.96h13.16c7.77,0,12.77,3.99,12.77,10.79v0.09c0,7.24-5.57,11.06-13.2,11.06h-4.21v8.77h-8.51    V145.96z M685.72,161.22c3.03,0,4.87-1.58,4.87-3.99v-0.09c0-2.63-1.85-4.03-4.91-4.03h-3.99v8.12H685.72z" />
                    <path className="st3" d="M737.92,145.96h8.55v30.71h-8.55V145.96z" />
                </g>
            </g>
        </svg>
    )
}

export default LogoAPI;